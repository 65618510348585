.cardpreview.student {
  background-image: url('../images/cardtemplate1.png');
}
.cardpreview.medewerker, .cardpreview.employee {
  background-image: url('../images/cardtemplate2.png');
}

.cardpreview {
  font-family:       Kiro, sans-serif;
  position:          relative;
  background-size:   contain;
  background-repeat: no-repeat;
  width:             350px;
  height:            221px;

  * {
    display: none;
  }

  .name {
    display: block;
    position: absolute;
    top: 182px;
    left: 12px;
    font-size: 21px;
    font-weight: bold;

    .title, .initials, .nameoncard {
      display: none;
    }

    .firstname, .middlename, .lastname {
      display: inline;
    }

    .firstname::after, .middlename::after {
      content: ' ';
    }
  }

  .group {
    display: block;
    position: absolute;
    top: 82px;
    left: 12px;
    font-size: 19px;
    font-weight: bold;

  }

  .cardnumber {
    display: block;
    position: absolute;
    top: 110px;
    left: 12px;
    font-size: 13px;
    line-height: 14px;
  }

  .cardnumber::before {
    content: 'CardID \A';
    white-space: pre;
  }

  .photo {
    display: block;
    position: absolute;
    width: 90px;
    height: 120px;
    top:  15px;
    left: 245px;
  }
}

.cardpreview.student {
  .profilenumber {
    display: block;
    position: absolute;
    top: 110px;
    left:68px;
    font-size: 13px;
    line-height: 14px;
  }

  .profilenumber::before {
    content: 'OV-NR. \A';
    white-space: pre;
  }
}
