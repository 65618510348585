@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-Regular.ttf');
   font-weight: normal;
   font-weight: 400;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-Italic.ttf');
   font-style: italic;
   font-weight: normal;
   font-weight: 400;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-Light.ttf');
   font-weight: 300;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-LightItalic.ttf');
   font-style: italic;
   font-weight: 300;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-SemiBold.ttf');
   font-weight: 600;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-SemiBoldItalic.ttf');
   font-style: italic;
   font-weight: 600;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-Bold.ttf');
   font-weight: bold;
   font-weight: 700;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-BoldItalic.ttf');
   font-style: italic;
   font-weight: bold;
   font-weight: 700;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-ExtraBold.ttf');
   font-weight: 800;
}

@font-face {
   font-family: OpenSans;
   src: url('./OpenSans-ExtraBoldItalic.ttf');
   font-style: italic;
   font-weight: 800;
}
