@font-face {
   font-family: Kiro;
   src: url('./Kiro-Regular.ttf');
   font-weight: normal;
   font-weight: 400;
}

@font-face {
   font-family: Kiro;
   src: url('./Kiro-Bold.ttf');
   font-weight: bold;
   font-weight: 700;
}

