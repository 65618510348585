table#person, table#card {
  col.firstcol {
    width: 200px;
  }

  /*
  tbody.name, tbody.other, tbody.card {
    tr.firstname,
    tr.profilenumber,
    tr.title,
    tr.startdate,
    tr.enddate,
    tr.department,
    tr.nameoncard {
      display: none;
    }
  }
  */
}
